.helper{

  &__layout{
    position: relative;
    width: 75%;
    margin: auto;
    margin-top: -34.75rem;

    ._opened-select &{
      z-index: 99;
    }

    @include md{
      width: 100%;
    }

    &-head{
      display: flex;
      align-items: flex-end;
      height: 18.5rem;

      @include md{
        height: 15rem;
      }

      @include tablet{
        height: 10rem;
      }
    }

    &-title{
      font-size: 4.5rem;
      font-weight: 200;
      line-height: 5rem;
      letter-spacing: 0;
      color: #fff;
      margin-bottom: 5.875rem;

      @include md{
        font-size: 3.3rem;
        padding-left: 45px;
      }

      @include tablet{
        font-size: 2.87rem;
        padding-left: 40px;
        margin-bottom: 3.875rem;
      }
    }

    &-content{
      @include md{
        background: #fff;
      }
    }

    &-shadow{
      position: relative;

      &>div{
        position: relative;
        z-index: 10;
      }

      &:after{
        display: block;
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0 0.9375rem 3.75rem 0 rgba(0, 0, 0, 0.05);

        @include md{
          display: none;
        }
      }
    }

    &-flat{
      padding: 85px 8.2%;

      @include md{
        padding: 80px 32px 0 32px;
      }
      @include phone {
        padding: 80px 16px 0 16px;
      }
    }
  }

}
