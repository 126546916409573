.popup-select {
  position: relative;
  display: inline-block;
  text-align: left;
  z-index: 99;

  @include md{
    position: static;
  }

  &__current {
    &-name {
      position: relative;
      color: $rouge;
      font-size: 18px;
      font-weight: 600;
      padding-right: 23px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -2px;
        display: block;
        content: "";
        width: 10px;
        height: 5px;
        background: url(../images/icons/arrow.svg) no-repeat center;
        background-size: 100%;
      }
    }
  }

  &__popup {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    padding: 14px 0;
    width: 270px;
    transition: all .3s;

    &-close{
      position: absolute;
      top: 25px;
      right: 25px;
      width: 19px;
      height: 19px;
      background: url(../images/icons/close.svg) no-repeat center;
      background-size: 100%;
      display: none;
      cursor: pointer;

      @include md{
        display: block;
      }
    }

    @include md{
      position: fixed;
      top: -95px;
      bottom: 0;
      z-index: 9999;
      right: 0;
      width: 480px;
      padding-top: 75px;


      &-close{
        display: block;
      }
    }

    @include tablet{
     top: 0;
    }

    @include phone{
      left: 0;
      width: 100%;
    }

    .--left-position &{
      @include md{
        right: auto;
        left: 0;
      }
    }

    ._focus &{
      display: block;
    }

    &-inner{
      max-height: 260px;
      overflow: auto;

      @include md{
        max-height: 100%;
      }

      &::-webkit-scrollbar {
        width: 5px;

      }

      &::-webkit-scrollbar-thumb {
        background: #d8d8d8;
        border-radius: 4px;
        margin-right: 10px;
        right: 10px;
        position: absolute;
        transform: translateX(-10px);

      }

      &::-webkit-scrollbar-track {
        background: none;
        border-radius: 4px;
      }
    }
  }

  &__value {
    text-align: left;
    display: block;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    color: #000;
    transition: all .3s;

    &._current{
      color: $rouge;
    }

    &:hover{
      background: #eaeaea;
      color: $rouge;
    }
  }

  &__group {
    &-title {
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
      color: #000;
      cursor: pointer;
      transition: all .3s;
      position: relative;

      body & .popup-select__value{
        padding: 0px;
      }

      &:hover{
        background: #eaeaea;
      }
    }

    &-opener{
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      width: 12px;
      height: 12px;
      background: url(../images/icons/plus2.svg) no-repeat center;
      margin-top: -6px;
      background-size: 100%;

      @include md{
        right: 32px;
      }

      ._opened &{
        background: url(../images/icons/minus2.svg) no-repeat center;
      }
    }

    &-inner{
      display: none;

      ._opened &{
        display: block;
      }
    }

    .popup-select__value {
      padding: 0 32px;
    }
  }
}

.header {
    @include md {
        position: static;
    }
}
