.detail-news{
  padding: 5.25rem 5.625rem 6.25rem 6.25rem;

  @include tablet{
    padding: 2.25rem 2.625rem 3.25rem 3.25rem;
  }
  @include phone{
    padding: 3rem 1.5rem;
  }

  &__head {
      margin-left: 0;
  }

  &__text img {
      object-fit: contain;
      width: 100%;
      height: 100%;
  }
}
