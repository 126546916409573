.btn {
  &.--size-big {
    height: 4.5rem;
    padding: 0 3.9rem;
  }

  &._loading {
    cursor: default;
    border-color: $brown-grey;
    background: $brown-grey;


    .btn__text{
      color: #fff !important;
    }
  }
}