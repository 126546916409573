.menu {

  &__item {
    @media only screen and (min-width: 1025px) {
      height: 24.75rem;
    }

    &._small {
      height: unset;
    }

    &._row-3 {
      @media only screen and (min-width: 1025px) {
        margin-top: -90px;
      }
    }

  }

  &--scroll {
      @media (max-height: 1000px) {
        overflow-y: scroll;
      }
  }

  &--open &__item {

    &:nth-child(1) {
      .menu__link {
        &:nth-child(10) {
          -webkit-animation: transitionRight .6s ease 1.6s forwards;
          animation: transitionRight .6s ease 1.6s forwards;
        }
      }
    }

    &:nth-child(2) {
      .menu__link {
        &:nth-child(7) {
          -webkit-animation: transitionRight .6s ease 1.5s forwards;
          animation: transitionRight .6s ease 1.5s forwards;
        }
      }
    }

    &:nth-child(7) {
      -webkit-animation: fadeUpMenuItem .4s ease 1.9s forwards;
      animation: fadeUpMenuItem .4s ease 1.9s forwards;
    }

    &:nth-child(8) {
      -webkit-animation: fadeUpMenuItem .4s ease 2.3s forwards;
      animation: fadeUpMenuItem .4s ease 2.3s forwards;
    }

    &:nth-child(9) {
      -webkit-animation: fadeUpMenuItem .4s ease 2.5s forwards;
      animation: fadeUpMenuItem .4s ease 2.5s forwards;
    }

    &:nth-child(10) {
      -webkit-animation: fadeUpMenuItem .4s ease 2.7s forwards;
      animation: fadeUpMenuItem .4s ease 2.7s forwards;
    }

    &:nth-child(11) {
      -webkit-animation: fadeUpMenuItem .4s ease 2.9s forwards;
      animation: fadeUpMenuItem .4s ease 2.9s forwards;
    }
  }

}