.footer {

  &__nav {
      justify-content: unset;
      flex-flow: row wrap;

      @include md {
        flex-flow: column;
      }

    &-item {
      padding-right: 32px;
      padding-bottom: 10px;

      @include md {
        padding-right: 0;
        padding-bottom: 0;
      }

    }
  }
}