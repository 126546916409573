body{
  &._opened-select{
    @include tablet{
      overflow: hidden;

      .helper__layout{
        transform: none!important;
      }
    };
  }
}