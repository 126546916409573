.audiojs audio {
  position: absolute;
  left: -1px;
  display: none;
}

.audiojs {

}

.audiojs .play-pause {
  margin: 0px;
  float: left;
  overflow: hidden;
}


.audiojs .play {
  display: block;
}

.audiojs .scrubber {
  display: none;
}

.audiojs .progress {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 14px;
  width: 0px;
  background: #ccc;
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ccc), color-stop(0.5, #ddd), color-stop(0.51, #ccc), color-stop(1, #ccc));
  background-image: -moz-linear-gradient(center top, #ccc 0%, #ddd 50%, #ccc 51%, #ccc 100%);
}

.audiojs .loaded {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 14px;
  width: 0px;
  background: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #222), color-stop(0.5, #333), color-stop(0.51, #222), color-stop(1, #222));
  background-image: -moz-linear-gradient(center top, #222 0%, #333 50%, #222 51%, #222 100%);
}

.audiojs .time {
  float: left;
  color: $rouge;
  margin-left: 6px;
  display: none;

}

.audiojs.playing .time {
  display: inline-block;
  vertical-align: middle;
}

.audiojs .time em {
  padding: 0px 2px 0px 0px;
  font-style: normal;
}

.audiojs .time strong {
  display: none;
}

.audiojs .error-message {
  float: left;
  display: none;
  margin: 0px 10px;
  height: 36px;
  width: 400px;
  overflow: hidden;
  line-height: 36px;
  white-space: nowrap;
  color: #fff;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}

.audiojs .error-message a {
  color: #eee;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #999;
  white-space: wrap;
}

.audiojs .play {
  white-space: nowrap;
  cursor: pointer;

  &:before {
    display: inline-block;
    content: "";
    width: 12px;
    vertical-align: middle;
    height: 18px;
    background: url(../images/icons/play.svg) no-repeat center;
    margin-right: 6px;
    transition: all .3s;

    .--type-short & {
      background: url(../images/icons/play-gray.svg) no-repeat center;
    }
  }

  &:after {
    display: inline-block;
    vertical-align: middle;
    //content: "Слушать";
    transition: all .3s;
  }

  &:hover {
    &:before {
      background: url(../images/icons/play-hover.svg) no-repeat center;
    }

    &:after {
      color: $rouge;
    }
  }
}

html[lang=ru] .audiojs .play {
  &:after {
    content: "Слушать";
  }
}
html[lang=en] .audiojs .play {
  &:after {
    content: "Listen";
  }
}

.audiojs .pause {
  display: none;
  vertical-align: middle;
  cursor: pointer;

  &:before {
    display: inline-block;
    content: "";
    width: 12px;
    height: 18px;
    background: url(../images/icons/pause.svg) no-repeat center;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }

}

.audiojs.playing .pause {
  display: inline-block;
}

.audiojs .error {
  height: auto;
  display: none;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2) {
  .audiojs .play, .audiojs .loading, .audiojs .error, .audiojs .pause {
    background-image: url("$2");
    -webkit-background-size: 30px 120px;
    -moz-background-size: 30px 120px;
    -o-background-size: 30px 120px;
    background-size: 30px 120px;
  }
}

.playing .play, .playing .loading, .playing .error {
  display: none;
}

.playing .pause {

}

.loading .play, .loading .pause, .loading .error {
  display: none;
}

.loading .loading {
  display: block;
}

.error .time, .error .play, .error .pause, .error .scrubber, .error .loading {
  display: none;
}

.error .error {
  display: block;
}

.error .play-pause p {
  cursor: auto;
}

.error .error-message {
  display: block;
}

._full-audio-player {
  .audiojs {
    display: flex !important;
    align-items: center;
  }

  .audiojs .play {
    white-space: nowrap;
    cursor: pointer;
    color: #fff;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "Слушать";
      transition: all .3s;
      font-size: 15px;
      margin-right: 10px;
      background: none;
      width: auto;
      height: auto;

    }

    &:after {
      display: inline-block;
      content: "";
      width: 72px;
      vertical-align: middle;
      height: 72px;
      background: url(../images/icons/play-big.svg) no-repeat center;
      transition: all .3s;

      .--type-short & {
        background: url(../images/icons/play-gray.svg) no-repeat center;
      }
    }

    &:hover {

      &:after {
        background: url(../images/icons/play-big-hover.svg) no-repeat center;
      }
    }
  }

  .audiojs.playing {
    .play-pause {
      order: 30;

      .pause {
        width: 72px;
        height: 72px;
        background: url(../images/icons/pause-big.svg) no-repeat center;

        &:before {
          display: none;
        }

        &:hover {
          background: url(../images/icons/pause-big-hover.svg) no-repeat center;
        }
      }
    }

    .scrubber {
      order: 10;
      display: block;
      position: relative;
      width: 200px;
      height: 4px;
      //opacity: 0.5;
      border-radius: 5px;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.5);

      .progress {
        background: #fff;

      }

      .loaded {
        background: none;

        opacity: 0.5;
      }
    }

    .time {
      order: 20;
      margin-left: 16px;
      margin-right: 27px;
      color: #fff;
      font-size: 15px;

      strong{
        display: none;
      }
    }
  }
}

:lang(en) {
  ._full-audio-player {

    .audiojs .play {

      &:before {
        content: "Listen";
      }

      &:after {
        content: "";
      }
    }
  }
}
:lang(ru) {
  ._full-audio-player {

    .audiojs .play {

      &:before {
        content: "Слушать";
      }

      &:after {
        content: "";
      }
    }
  }
}
