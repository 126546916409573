@include md {
  .nav {
    overflow-x: scroll;
    overflow-y: hidden;

    &__item {
      &--active {

       &:after {
         display: none;
       }

      }
    }
  }

}
@media only screen and (min-width: 1025px) {
  .nav {
    &__link {
      line-height: 1.5rem;
    }
  }
}