.review {
  &__head {
    &-player {

      @include tablet{
        margin: 15px 0;
        width: 100%;
      }
    }

    &-other {
      padding-bottom: 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 9.5rem;
      padding-top: 25px;

      @include md{
        padding-left: 5rem;
      }
      @include tablet{
        padding-bottom: 0;
      }

      @include tablet{
        flex-wrap: wrap;
      }
    }

    &-tags{
      //float: left;
      @include tablet{
        margin: 15px 0;
        width: 100%;
      }

    }

    &-description {
      &.--detail-news {
        flex-wrap: wrap;
        margin-left: 0;

        .review__head-s-title {
          flex: 1;

          @include phone{
            font-size: 2rem;
          }
        }
      }
    }


  }

  &-career {
    &__logo {
      &-block._en {
        width: 18.875rem;
        background: url(../images/about/logo-red-en.svg) no-repeat center/contain;
      }
    }
  }

}
