.about {
    padding: 0;



    &-page {
        overflow: visible;
    }

    &-wrap {
        position: relative;
        max-width: 80rem;

        @include md {
            .service__nav {
                display: none;
            }
        }




    }

    &__content {
        max-width: 57.5rem;
        position: relative;
        background-color: #fff;
    }

    &-review {
        background-image: none;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);

        &__text {
            font-size: 15px;
            margin-bottom: 0.9rem;
        }
    }

    &-desc {
        max-width: 57.5rem;
        padding-left: 3.05rem;
        margin-left: -8px;

        &__text {
            font-size: 15px;
            margin-bottom: 0.9rem;
        }
    }

    &-leaders {
        max-width: 57.5rem;
        .about-leaders__head {
            margin-bottom: 2rem;
        }
        .about-leaders__list {
            padding-left: 0;
            margin-right: -18px;
            margin-left: -18px;

            /* @include md {
              flex-wrap: wrap;
            }
            @include phone {
                margin-right: 0;
                margin-left: 0;
            } */
        }
        .about-leaders__item {
            max-width: calc(100% / 3 - 32px);
            margin-right: 18px;
            margin-left: 18px;

            /* @include md {
                margin-bottom: 2rem;
            }
            @include phone {
                max-width: 95%;
                margin-right: auto;
                margin-left: auto;
            } */
        }
        .about-leaders__image-container {
            width: 100%;
            height: 17.25rem;

            /* @include phone {
                height: 28rem;
            } */
        }

        .about-leaders__position,
        .about-leaders__name {
            font-size: 14px;
        }
    }

    &-certificate {

        &:not(.esm-cert) {
            max-width: 48rem;
        }

        @include md {
            max-width: 100%;
        }

        &__list {
            height: 40rem;
            @include md {
                height: auto;
            }
        }

        &__item {
            height: 20.5rem;

            @include md {
                height: 17.5rem;
            }
            padding-left: 0.8125rem;
            padding-right: 0.8125rem;
        }

        &__signature {
            font-size: 12px;
        }

        &__image-container {
            margin-bottom: 0.65rem;

            &::after {
                background-color: #bd1622;
                opacity: 1;
            }
        }
    }

    &-rewards {
        max-width: 57.5rem;

        &__text {
            font-size: 15px;
        }

        &--text-view .about-rewards__image {
            height: 20rem;
            margin-bottom: 3rem;

            @include md {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &-association {
        max-width: 57.5rem;
        padding: 0 6.25rem;
        padding-right: 2.5rem;

        @include md {
            padding: 0;
        }
    }
}

.association {

    &__item {
        width: calc(100% / 3);
        flex-basis: 50%;
        padding: 1.5rem 1.75rem;

        &::after {
            opacity: 1;
            width: 80%;
            height: 2px;
        }
    }

    &__header,
    &__text {
        font-size: 14px;
    }

    &__header {
        padding-top: 1.5rem;
    }
}

.swiper-container-horizontal {
    overflow-x: hidden;
}
