.press-center {
  &__head {
    margin-bottom: 20px;
    display: flex;
    &-tags {
      margin-right: 5%;
    }

    &-period {
      text-align: right;
      margin-left: auto;
    }


    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  &__tags {

        @include tablet {
            display: none;
        }

    &.--margin-bottom-small {
        @include tablet {
            display: block;
        }
    }

    &.--hide-none {
      display: block!important;
    }

    &.--on-small {
      display: none;

      @include tablet{
        display: block!important;

      }
    }
    &-item {
      display: inline-block;
      margin-right: 25px;
      margin-bottom: 15px;
      font-size: 11px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.73;
      letter-spacing: normal;
      color: $brown-grey;
      text-transform: uppercase;
      transition: all .3s;

      .--margin-bottom-small & {
        margin-bottom: 10px;
      }

      .--margin-bottom-none & {
        margin-bottom: 0px;
      }

      &.--current, &:hover {
        color: $rouge;
      }
    }

  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    &.--type-short{
      display: block;
    }
  }

  &__item {
    width: 49%;
    transition: all .3s;
    position: relative;
    margin-top: 25px;

    @include tablet{
      width: 100%;
    }

    .--type-short &{
      width: 100%;

      @include md{
        margin-top: 0;
      }
    }

    &:hover {
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    &-pic {
      //height: 260px;
      //line-height: 260px;
      line-height: 0;
      overflow: hidden;
      text-align: center;
      position: relative;

      @include tablet{
        //height: 400px;
        //line-height: 400px;
      }

      @include phone{
        //height: 240px;
        //line-height: 240px;
      }


      .--type-short &{
        display: none;
      }

      img {
        //object-fit: cover;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &-inner {
      padding: 32px 24px 80px 24px;

      @include phone {
        padding: 32px 16px 80px 16px;
      }

      .--type-short &{
        max-width: 938px;
        padding: 32px 24px;

        @include phone {
            padding: 32px 16px;
        }

        &:after{
          display: block;
          content: "";
          clear: both;
        }
      }
    }

    &-date {
      float: left;
      text-align: center;
      font-weight: 200;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.18;
      letter-spacing: 0px;
      color: #000;
      margin-right: 24px;

      margin-bottom: 15px;

      b {
        font-weight: 200;
        display: block;
        font-size: 36px;
      }

      span {
        font-size: 17px;
        display: block;
      }
    }

    &-title {
      display: block;
      overflow: hidden;
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0px;
      color: #000;
      transition: all .3s;
      margin-bottom: 15px;

      &:hover {
        color: $rouge;
      }

      @include phone{
        font-size: 16px;
      }
    }

    &-text {
      clear: both;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.73;
      letter-spacing: normal;
      color: $brownish-grey;

      .--type-short &{
        margin-bottom: 15px;
      }
    }

    &-tags {
      position: absolute;
      bottom: 22px;
      max-height: 48px;
      overflow: hidden;

      .--type-short &{
        position: relative;
        bottom: auto;
        float: left;

        @include tablet{
          float: none;
          margin-bottom: 15px;
        }
      }
    }

    &-meta {
      position: absolute;
      height: 52px;
      //top: 208px;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 10;
      padding: 0 24px;
      line-height: 52px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 13px;

      @include tablet{
        //top: 348px;
      }

      @include phone{
        //top: 188px;
      }

      .--type-short &{
        position: relative;
        float: right;
        top: auto;
        left: auto;
        width: auto;
        background: none;
        color: $brown-grey;
        height: auto;
        line-height: inherit;

        @include tablet{
          float: none;
          justify-content: flex-start;
          padding-left: 0;
        }
      }
    }

    &-duration {
      margin-right: 24px;

      .--type-short &{
        float: left;
      }
      &:before {
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px;
        content: "";
        width: 23px;
        height: 20px;
        background: url(../images/icons/duration.svg) no-repeat center;

        .--type-short &{
          background: url(../images/icons/duration-gray.svg) no-repeat center;
        }
      }

    }
  }

  &__button{
    text-align: center;
    margin-top: 65px;
    margin-bottom: 35px;
  }
}
