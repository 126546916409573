.header {
  &__menu {
    &-item{
      padding: 0 0.8rem 0;
      white-space: nowrap;

      @media screen and (max-width: 1280px){
        &._hidden-candidate{
          display: none;
        }
      }

      &--menu-button{
        z-index: 99;
      }

      @include md{
        white-space: normal;
      }
    }

    &-link {
      font-size: 0.8125rem;
    }
  }
}